/* Barra vertical */
::-webkit-scrollbar {
    width: 10px; /* Largura da barra de rolagem vertical */
    scrollbar-width: thin; /* Largura da barra de rolagem no Firefox */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Cor da trilha */
  }
  
  /* Thumb (polegar) da barra de rolagem vertical */
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; /* Cor do polegar */
    border-radius: 15px; /* Bordas arredondadas do polegar */
  }
  
  /* Barra de rolagem horizontal */
  ::-webkit-scrollbar:horizontal {
    height: 5px; /* Altura da barra de rolagem horizontal */
  }
  
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #c1c1c1; /* Cor do polegar */
    border-radius: 15px; /* Bordas arredondadas do polegar */
  }
  
  .gray-cell {
    background-color: #f7f5f5; /* Altere para a cor desejada */
    width: 100%;
}